.contact-container {
    display: flex;
    flex-direction: column;
    position: relative;
    background-image: url("image/contactBackground.png");
    background-size: cover;
    padding-top: 174px;
}

.contact-content {
    flex-grow: 1;
    margin-bottom: 50px;
}

.chatbox-susan {
    position: absolute;
    right: 60px;
    transform: translateY(-75px);
    opacity: 0;
    transition: opacity 500ms ease-in, transform 0.8s ease-in, -webkit-transform 0.8s ease-in;
}

.chatbox-susan.appear {
    transform: translateY(-25px);
    opacity: 1;
}

.chatbox-right {
    position: absolute;
    left: 105%;
    width: max-content;
    transform: translateY(-175px);
    opacity: 0;
    transition: opacity 500ms ease-in, transform 0.8s ease-in, -webkit-transform 0.8s ease-in;
}

.chatbox-right.appear {
    transform: translateY(-115px);
    opacity: 1;
}

.chatbox-left {
    position: absolute;
    right: 105%;
    width: max-content;
    transform: translateY(-175px);
    opacity: 0;
    transition: opacity 500ms ease-in, transform 0.8s ease-in, -webkit-transform 0.8s ease-in;
}

.chatbox-left.appear {
    transform: translateY(-115px);
    opacity: 1;
}